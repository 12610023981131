import React, { Fragment, ReactNode } from 'react';

import classNames from 'classnames';

import { ArrowRight } from '@ui/uikit/components/icons/mono/ArrowRight';
import { cn } from '@ui/uikit/utils';

import NextLink from './NextLink';

export interface PageHeaderBreadcrumbsItem {
  id: string;
  label: string;
  href?: string;
}

export interface PageHeaderProps {
  className?: string;
  title: ReactNode;
  breadcrumbs?: PageHeaderBreadcrumbsItem[];
}

const PageHeader = ({ breadcrumbs, title, className }: PageHeaderProps) => {
  return (
    <header className={cn('py-6 xl-msq:pt-10 xl-msq:pb-8', className)}>
      <nav aria-label="Breadcrumb" className="mb-2 xl-msq:mb-1">
        <ol className="flex items-center gap-0.5 text-caption-xs-m xl-msq:text-caption-xs-d text-base-text-tertiary">
          {breadcrumbs?.map((item, i) => {
            const isFirst = i === 0;
            const isLast = i === breadcrumbs.length - 1;

            return (
              <Fragment key={item.id}>
                {!isFirst && (
                  <li className="flex" role="presentation" aria-hidden="true">
                    <ArrowRight className="size-3 xl-msq:size-4" />
                  </li>
                )}
                <li className="flex">
                  {!isLast && (
                    <NextLink
                      href={item.href || ''}
                      className={classNames('hover:text-base-text-primary transition-all')}
                    >
                      {item.label}
                    </NextLink>
                  )}

                  {isLast && (
                    <span role="link" aria-disabled="true" aria-current="page">
                      {item.label}
                    </span>
                  )}
                </li>
              </Fragment>
            );
          })}
        </ol>
      </nav>

      <h1 className="text-h1-m xl-msq:text-h1-d">{title}</h1>
    </header>
  );
};

export default PageHeader;
